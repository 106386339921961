import React from 'react'

const Image = ({ url, alt = 'Imagem - Noz', webp = false, avif = false }) => {
  return (
    <picture>
      {avif && <source srcSet={avif} type="image/avif" />}
      {webp && <source srcSet={webp} type="image/webp" />}

      {url && <img src={url} alt={alt} />}
    </picture>
  )
}

export default Image
