import React from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import './SectionStyle.scss'

import Image from 'components/elements/Image'

import { ReactComponent as ArrowReturn } from 'assets/vectors/pages/product/hero-return.svg'
import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  const openModalCta = () => {
    const body = document.querySelector('body')
    body.dispatchEvent(new CustomEvent('openModalCta', { detail: 'produto' }))
  }

  return (
    <section className="section section-hero">
      <div className="circles">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div
              className="left-return appear-fade"
              appear="0"
              appear-delay="0"
            >
              <Link
                to="/produtos"
                title="Funcionalidades"
                aria-label="Funcionalidades"
              >
                <ArrowReturn />
                <span>Funcionalidades</span>
              </Link>
            </div>

            <div
              className="left-title appear-fade"
              appear="0"
              appear-delay="100"
            >
              {data.general.title}
            </div>

            <div
              className="left-description appear-fade"
              appear="0"
              appear-delay="200"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.general.description),
                }}
              ></p>
            </div>

            <div className="left-cta appear-fade" appear="0" appear-delay="300">
              <button
                type="button"
                title="Fale com um especialista"
                aria-label="Fale com um especialista"
                onClick={() => openModalCta()}
              >
                <span>{data.hero.buttonText}</span>
                <ArrowUp />
              </button>
            </div>
          </div>

          <div className="right appear-fade" appear="0" appear-delay="400">
            <div
              className="right-desktop appear-fade-vertical"
              appear="0"
              appear-delay="400"
            >
              <Image
                url={data.hero.imageDesktop.url}
                webp={data.hero.imageDesktop.webp}
                avif={data.hero.imageDesktop.avif}
                alt={data.hero.imageDesktop.alt}
              />
            </div>

            <div
              className="right-phone appear-fade-vertical"
              appear="0"
              appear-delay="400"
            >
              <Image
                url={data.hero.imagePhone.url}
                webp={data.hero.imagePhone.webp}
                avif={data.hero.imagePhone.avif}
                alt={data.hero.imagePhone.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
