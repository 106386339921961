import { useEffect } from 'react'

const Appear = () => {
  const animate = (entries) => {
    entries.forEach((entry) => {
      const appear = entry.target
      const delay = appear.getAttribute('appear-delay') || 0

      if (appear.classList.contains('video-play')) {
        appear.play()
        appear.dispatchEvent(new Event('appeared'))
      }

      if (entry.isIntersecting) {
        if (appear.classList.contains('appear')) return

        setTimeout(function () {
          appear.classList.add('appear')
          appear.dispatchEvent(new Event('appeared'))
        }, delay)
      } else {
        if (entry.boundingClientRect.top < 0) return
        if (entry.boundingClientRect.left < 0) return

        if (appear.classList.contains('appear')) {
          appear.classList.remove('appear')
        }
      }
    })
  }

  useEffect(() => {
    const body = document.querySelector('body')

    const appear = () => {
      const appears = document.querySelectorAll('[appear]')

      for (let i = 0; i < appears.length; i++) {
        const item = appears[i]
        const distance = Number(item.getAttribute('appear'))

        new window.IntersectionObserver((entries) => animate(entries), {
          threshold: distance,
        }).observe(item)
      }
    }

    body.addEventListener('pageChanged', () => appear())
  }, [])

  return ''
}

export default Appear
