const progressScreen = (option, message) => {
  const body = document.querySelector('body')

  if (option === 'create') {
    const screenHtml = document.createElement('div')
    const screenHtmlMessage = document.createElement('div')
    const screenHtmlSpinner = document.createElement('div')
    const screenHtmlSpinnerSpan1 = document.createElement('span')
    const screenHtmlSpinnerSpan2 = document.createElement('span')
    screenHtml.classList.add('screen-progress')
    screenHtmlMessage.classList.add('screen-message')
    screenHtmlMessage.innerHTML = message
    screenHtmlSpinner.classList.add('spinner')
    screenHtmlSpinner.appendChild(screenHtmlSpinnerSpan1)
    screenHtmlSpinner.appendChild(screenHtmlSpinnerSpan2)
    screenHtml.appendChild(screenHtmlMessage)
    screenHtml.appendChild(screenHtmlSpinner)

    body.classList.add('shadow')
    body.appendChild(screenHtml)
    setTimeout(() => screenHtml.classList.add('open'), 300)
  } else {
    const screen = document.querySelector('.screen-progress')
    const screenMessage = screen.querySelector('.screen-message')

    if (option === 'change') {
      screenMessage.innerHTML = message
    } else if (option === 'close') {
      screenMessage.innerHTML = message

      setTimeout(
        function () {
          screen.classList.remove('open')

          setTimeout(() => body.classList.remove('shadow'), 500)
          setTimeout(() => screen.remove(), 1000)
        },
        message.includes('Olá') ? 1000 : 1000,
      )
    }
  }
}

export default progressScreen
