import styled from "styled-components";

export const ContainerPrivacyPolicy = styled.main`
  width: 100%;
  max-height: 100%;
  margin-top: 2rem;

  display: flex;
  flex-direction: column;

  padding: 5rem 5rem;

  @media (max-width: 550px) {
    h4{
      text-align: center;
    }
    padding: 5rem 1.5rem;
    
    span.last-update{
      margin: 0 auto;
      text-align: center;
    }
    
    span.subtitle{
      margin: 1.5rem 0 0.5rem 0 !important;
      text-align: center;
    }
  }

  h4 {
    font-weight: bold;
    margin: 0 auto;
  }

  span.last-update{
    margin-left: auto;
    font-style: italic;
    margin-top: 2rem;
  }

  span.subtitle {
    margin: 1.5rem 0 0.5rem -1rem;
  }

  ol > li {
    list-style-type: decimal !important;
    &:nth-child(n + 10) {
      margin-left: 0.5rem;

      div#main{
        margin-left: -0.6rem;
      }
    }
  }

  ol.list {
    li {
      margin: 1rem 0;
    }
  }
`;

export const ContainerSecondLevelList = styled.div`
  margin-left: -1rem;

 >div#main{
  padding: 1rem 0;
 }
`

export const TermThirdLevel = styled.div`
  padding-bottom: 1rem;
  margin-left: 3rem;
`
