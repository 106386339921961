import React from 'react'
import * as DOMPurify from 'dompurify'
import './SectionStyle.scss'

import Image from 'components/elements/Image'

const Section = ({ data }) => {
  return (
    <section className="section section-content">
      <div className="container">
        <div className="items">
          {data.content.map((item, index) => (
            <div className="item" key={`product-content-${index}`}>
              <div className="item-left appear-fade" appear="0.2">
                <div className="item-left--image">
                  <Image
                    url={item.image.url}
                    webp={item.image.webp}
                    avif={item.image.avif}
                    alt={item.image.alt}
                  />
                </div>
              </div>

              <div className="item-right">
                <div className="item-right--title appear-fade" appear="0.2">
                  {item.title}
                </div>

                <div
                  className="item-right--description appear-fade"
                  appear="0.2"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.description),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Section
