import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as DOMPurify from 'dompurify'
import InputMask from 'react-input-mask'
import progressScreen from 'utils/progress-screen'
import './ModalCtaStyle.scss'

import { ReactComponent as Close } from 'assets/vectors/general/header-close.svg'
import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'
import { ReactComponent as ArrowDown } from 'assets/vectors/general/form-arrow-down.svg'

const Header = () => {
  const navigate = useNavigate()

  const [data, setData] = useState(window.page_options || false)
  const [openModal, setOpenModal] = useState()
  const [phone, setPhone] = useState(0)
  const [contactFrom, setContactFrom] = useState('')

  useEffect(() => {
    const body = document.querySelector('body')

    const setupFields = () => {
      const modal = document.querySelector('#main-modal-cta')
      const form = modal.querySelector('form')
      const fields = form.querySelectorAll('.field')

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const parent = field.parentElement

        field.addEventListener('focus', () =>
          parent.classList.remove('invalid'),
        )
      }
    }

    body.addEventListener('loadedOptions', function () {
      setData(window.page_options)

      setTimeout(() => setupFields(), 100)
    })

    body.addEventListener('openModalCta', function (type) {
      setContactFrom(type.detail)
      setOpenModal(true)
    })
  }, [])

  const setupPhone = (e) => {
    const field = e.target
    const value = field.value

    setPhone(value.length)
  }

  const formSubmit = (e) => {
    e.preventDefault()

    const form = e.target

    if (form.classList.contains('sending')) return
    form.classList.add('sending')

    const groups = form.querySelectorAll('.field-group')
    const name = groups[0].querySelector('.field').value
    const enterprise = groups[1].querySelector('.field').value
    const email = groups[2].querySelector('.field').value
    const phone = groups[3].querySelector('.field').value
    const job = groups[4].querySelector('.field').value
    const employees = groups[5].querySelector('.field').value
    const department = groups[6].querySelector('.field').value
    const objective = groups[7].querySelector('.field').value
    const message = groups[8].querySelector('.field').value

    progressScreen('create', 'Enviando mensagem...')

    if (name.length === 0) groups[0].classList.add('invalid')
    if (enterprise.length === 0) groups[1].classList.add('invalid')
    if (email.length === 0) groups[2].classList.add('invalid')
    if (phone.length < 14) groups[3].classList.add('invalid')
    if (job.length === 'none') groups[4].classList.add('invalid')
    if (employees === 'none') groups[5].classList.add('invalid')
    if (department === 'none') groups[6].classList.add('invalid')
    if (objective === 'none') groups[7].classList.add('invalid')
    if (message.length === 0) groups[8].classList.add('invalid')

    const invalids = form.querySelectorAll('.field-group.invalid')

    if (invalids.length > 0) {
      const text = 'Existem campos inválidos.'

      setTimeout(() => progressScreen('close', text), 1500)
      form.classList.remove('sending')

      return
    } else {
      const formData = new FormData()
      formData.append('nome', name)
      formData.append('empresa', enterprise)
      formData.append('cargo', job)
      formData.append('departamento', department)
      formData.append('objetivo', objective)
      formData.append('funcionarios', employees)
      formData.append('email', email)
      formData.append('telefone', phone)
      formData.append('mensagem', message)

      let url = process.env.REACT_APP_WP_FORM_CONTACT
      url = url + '/250/feedback'

      const request = axios.post(url, formData)

      request.then(() => {
        const text = 'Mensagem enviada com sucesso!'

        setOpenModal(false)
        progressScreen('close', text)
        navigate(`/sucesso-contato-${contactFrom}`)
      })
    }
  }

  return (
    <div id="main-modal-cta" className={openModal ? 'active' : ''}>
      <div className="background" onClick={() => setOpenModal(false)}></div>

      {data && (
        <div className="wrapper">
          <div className="wrapper-close">
            <button
              type="button"
              aria-label="Fechar"
              onClick={() => setOpenModal(false)}
            >
              <Close />
            </button>
          </div>

          <div
            className="wrapper-title"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.modal.title),
            }}
          ></div>

          <div className="wrapper-description">
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.modal.description),
              }}
            ></p>
          </div>

          <form className="wrapper-form form" onSubmit={(e) => formSubmit(e)}>
            <div className="form-flex">
              <div className="field-group">
                <div className="label">nome e sobrenome</div>

                <input
                  type="text"
                  className="field"
                  name="nome"
                  placeholder="Qual é o seu nome?"
                />
              </div>

              <div className="field-group">
                <div className="label">empresa</div>

                <input
                  type="text"
                  className="field"
                  name="empresa"
                  placeholder="Qual é o nome da sua empresa?"
                />
              </div>

              <div className="field-group">
                <div className="label">E-mail corporativo</div>

                <input
                  type="email"
                  className="field"
                  name="email"
                  placeholder="Qual é o seu e-mail?"
                />
              </div>

              <div className="field-group">
                <div className="label">Celular</div>

                <InputMask
                  className="field"
                  type="tel"
                  mask={phone === 15 ? '(99) 99999-9999' : '(99) 9999-99999'}
                  maskChar=""
                  placeholder="Qual é o seu número de celular?"
                  name="telefone"
                  onChange={(e) => setupPhone(e)}
                />
              </div>

              <div className="field-group">
                <div className="label">Cargo</div>

                <select className="field">
                  <option value="none">Selecione uma opção</option>
                  <option value="C-level">C-level</option>
                  <option value="Gerente/Coordenador/Head">
                    Gerente/Coordenador/Head
                  </option>
                  <option value="Coordenador/Especialista">
                    Coordenador/Especialista
                  </option>
                  <option value="Analista">Analista</option>
                  <option value="Estagiário">Estagiário</option>
                  <option value="Outros">Outros</option>
                </select>

                <ArrowDown />
              </div>

              <div className="field-group">
                <div className="label">departamento</div>

                <select className="field" name="departamento">
                  <option value="none">Selecione uma opção</option>
                  <option value="Branding e Marca">Branding e Marca</option>
                  <option value="Comunicação Interna">
                    Comunicação Interna
                  </option>
                  <option value="RH e Gestão de Pessoas">
                    RH e Gestão de Pessoas
                  </option>
                  <option value="Experiência de colaborador">
                    Experiência de colaborador
                  </option>
                  <option value="Experiência do Cliente">
                    Experiência do Cliente
                  </option>
                  <option value="Community Manager">Community Manager</option>
                  <option value="Inovação e Tecnologia">
                    Inovação e Tecnologia
                  </option>
                  <option value="Marketing e Trade Marketing">
                    Marketing e Trade Marketing
                  </option>
                </select>

                <ArrowDown />
              </div>

              <div className="field-group">
                <div className="label">Objetivo com o NOZ</div>

                <select className="field">
                  <option value="none">Selecione uma opção</option>
                  <option value="Comunicação com colaboradores">
                    Comunicação com colaboradores
                  </option>
                  <option value="Employee Experience">
                    Employee Experience
                  </option>
                  <option value="Programa de relacionamento com clientes">
                    Programa de relacionamento com clientes
                  </option>
                  <option value="Comunicação com representantes/consultores/promotores">
                    Comunicação com representantes/consultores/promotores
                  </option>
                  <option value="Relacionamento com Franquias">
                    Relacionamento com Franquias
                  </option>
                  <option value="Fortalecimento de Marca">
                    Fortalecimento de Marca
                  </option>
                </select>

                <ArrowDown />
              </div>

              <div className="field-group">
                <div className="label">número de usuários</div>

                <select className="field" name="departamento">
                  <option value="none">Selecione uma opção</option>

                  {data.modal.formSelect.map((item, index) => (
                    <option key={`modal-cta-form-${index}`} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>

                <ArrowDown />
              </div>
            </div>

            <div className="field-group">
              <div className="label">Como podemos te ajudar?</div>

              <textarea
                className="field"
                placeholder="Digite sua mensagem"
              ></textarea>
            </div>

            <p className="privacy">
              <small>
                Ao preencher o formulário, você está ciente que o App NOZ poderá
                enviar comunicações conforme os seus interesses. Você pode
                modificar as suas permissões a qualquer tempo. Para mais
                informações, confira a nossa{' '}
                <Link
                  to="/politica-privacidade"
                  title="Ir para a página"
                  aria-label="Ir para a página"
                >
                  Política de Privacidade
                </Link>
                .
              </small>
            </p>
            <div className="form-submit">
              <button type="submit" title="Enviar" aria-label="Enviar">
                <span>enviar</span>
                <ArrowUp />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default Header
