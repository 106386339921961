import React, { useState, useEffect } from 'react'
import API from 'api'
import { Link } from 'react-router-dom'
import './HeaderStyle.scss'

import { ReactComponent as Logo } from 'assets/vectors/general/logo.svg'
import { ReactComponent as Menu } from 'assets/vectors/general/header-menu.svg'
import { ReactComponent as Close } from 'assets/vectors/general/header-close.svg'
import { ReactComponent as Instagram } from 'assets/vectors/general/social-instagram.svg'
import { ReactComponent as Facebook } from 'assets/vectors/general/social-facebook.svg'
import { ReactComponent as Linkedin } from 'assets/vectors/general/social-linkedin.svg'

const Header = () => {
  const [data, setData] = useState()
  const [menuMobile, setMenuMobile] = useState()

  const openModalCta = () => {
    const body = document.querySelector('body')
    body.dispatchEvent(new CustomEvent('openModalCta', { detail: 'menu' }))
  }

  useEffect(() => {
    if (menuMobile === undefined) return

    const body = document.querySelector('body')

    if (menuMobile) {
      body.classList.add('not-scrollable')
    } else {
      body.classList.remove('not-scrollable')
    }
  }, [menuMobile])

  useEffect(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--initial-vh', `${vh}px`)
    document.documentElement.style.setProperty('--mobile-vh', `${vh}px`)

    window.addEventListener('resize', () => {
      const newVH = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--mobile-vh', `${newVH}px`)
    })
  }, [])

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_options',
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      window.page_options = info
      setData(info)

      body.dispatchEvent(new Event('loadedOptions'))
    })
  }, [])

  return (
    <header id="main-header">
      {data && (
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-logo">
              <Link
                to="/"
                title="Ir para a página principal"
                aria-label="Ir para a página principal"
              >
                <Logo />
              </Link>
            </div>
          </div>

          <div className="right">
            <div className="right-menu">
              <button
                type="button"
                aria-label="Abrir menu"
                onClick={() => setMenuMobile(true)}
              >
                <Menu />
              </button>
            </div>

            <div className="right-navigation">
              <ul>
                <li>
                  <Link
                    to="/produtos"
                    title="Ir para a página"
                    aria-label="Ir para a página"
                  >
                    Soluções
                  </Link>
                </li>

                <li>
                  <Link
                    to="/cases"
                    title="Ir para a página"
                    aria-label="Ir para a página"
                  >
                    Cases
                  </Link>
                </li>

                {data.menu.vacancies && (
                  <li>
                    <a
                      href={data.menu.vacancies}
                      title="Vagas"
                      aria-label="Vagas"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vagas
                    </a>
                  </li>
                )}

                <li>
                  <Link
                    to="/blog"
                    title="Ir para a página"
                    aria-label="Ir para a página"
                  >
                    Blog
                  </Link>
                </li>
              </ul>
            </div>

            <div className="right-cta">
              <button
                type="button"
                title={data.menu.buttonText}
                aria-label={data.menu.buttonText}
                onClick={() => openModalCta()}
              >
                {data.menu.buttonText}
              </button>
            </div>
          </div>
        </div>
      )}

      {data && (
        <div className={`menu-mobile ${menuMobile ? 'active' : ''}`}>
          <div className="logo">
            <Link
              to="/"
              title="Ir para a página principal"
              aria-label="Ir para a página principal"
              onClick={() => setMenuMobile(false)}
            >
              <Logo />
            </Link>
          </div>

          <div className="close">
            <button
              type="button"
              aria-label="Fechar"
              onClick={() => setMenuMobile(false)}
            >
              <Close />
            </button>
          </div>

          <div className="container">
            <div className="wrapper">
              <div className="navigation">
                <ul>
                  <li>
                    <Link
                      to="/produtos"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                      onClick={() => setMenuMobile(false)}
                    >
                      Soluções
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/cases"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                      onClick={() => setMenuMobile(false)}
                    >
                      Cases
                    </Link>
                  </li>

                  {data.menu.vacancies && (
                    <li>
                      <a
                        href={data.menu.vacancies}
                        title="Vagas"
                        aria-label="Vagas"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vagas
                      </a>
                    </li>
                  )}

                  <li>
                    <Link
                      to="/blog"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                      onClick={() => setMenuMobile(false)}
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="cta">
                <button
                  type="button"
                  title={data.menu.buttonText}
                  aria-label={data.menu.buttonText}
                  onClick={() => {
                    openModalCta()
                    setMenuMobile(false)
                  }}
                >
                  {data.menu.buttonText}
                </button>
              </div>

              <div className="social">
                <ul>
                  {data.social.instagram && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="0"
                    >
                      <a
                        href={data.social.instagram}
                        title="Acessar rede social"
                        aria-label="Acessar rede social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Instagram />
                      </a>
                    </li>
                  )}

                  {data.social.facebook && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="100"
                    >
                      <a
                        href={data.social.facebook}
                        title="Acessar rede social"
                        aria-label="Acessar rede social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Facebook />
                      </a>
                    </li>
                  )}

                  {data.social.linkedin && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="200"
                    >
                      <a
                        href={data.social.linkedin}
                        title="Acessar rede social"
                        aria-label="Acessar rede social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Linkedin />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
