import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './FooterStyle.scss'

import { ReactComponent as Logo } from 'assets/vectors/general/logo.svg'
import { ReactComponent as Instagram } from 'assets/vectors/general/social-instagram.svg'
import { ReactComponent as Facebook } from 'assets/vectors/general/social-facebook.svg'
import { ReactComponent as Linkedin } from 'assets/vectors/general/social-linkedin.svg'

const Footer = () => {
  const [data, setData] = useState(window.page_options || false)

  const openModalCta = () => {
    const body = document.querySelector('body')
    body.dispatchEvent(new CustomEvent('openModalCta', { detail: 'rodape' }))
  }

  useEffect(() => {
    const body = document.querySelector('body')

    body.addEventListener('loadedOptions', function () {
      setData(window.page_options)
    })
  }, [])

  return (
    <footer id="main-footer">
      {data && (
        <div className="container">
          <div className="flex-wrapper">
            <div className="left">
              <div className="left-logo appear-fade" appear="0.2">
                <Logo />
              </div>

              <div className="left-navigation">
                <ul>
                  <li
                    className="appear-fade-horizontal"
                    appear="0.2"
                    appear-delay="0"
                  >
                    <Link
                      to="/produtos"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                    >
                      Soluções
                    </Link>
                  </li>

                  <li
                    className="appear-fade-horizontal"
                    appear="0.2"
                    appear-delay="100"
                  >
                    <Link
                      to="/cases"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                    >
                      Cases
                    </Link>
                  </li>

                  {data.menu.vacancies && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="200"
                    >
                      <a
                        href={data.menu.vacancies}
                        title="Vagas"
                        aria-label="Vagas"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vagas
                      </a>
                    </li>
                  )}

                  <li
                    className="appear-fade-horizontal"
                    appear="0.2"
                    appear-delay="300"
                  >
                    <Link
                      to="/blog"
                      title="Ir para a página"
                      aria-label="Ir para a página"
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="right">
              <div className="right-cta appear-fade" appear="0.2">
                <button
                  type="button"
                  title={data.menu.buttonText}
                  aria-label={data.menu.buttonText}
                  onClick={() => openModalCta()}
                >
                  {data.menu.buttonText}
                </button>
              </div>

              <div className="right-social">
                <ul>
                  {data.social.instagram && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="0"
                    >
                      <a
                        href={data.social.instagram}
                        title="Acessar rede social"
                        aria-label="Acessar rede social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Instagram />
                      </a>
                    </li>
                  )}

                  {data.social.facebook && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="100"
                    >
                      <a
                        href={data.social.facebook}
                        title="Acessar rede social"
                        aria-label="Acessar rede social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Facebook />
                      </a>
                    </li>
                  )}

                  {data.social.linkedin && (
                    <li
                      className="appear-fade-horizontal"
                      appear="0.2"
                      appear-delay="200"
                    >
                      <a
                        href={data.social.linkedin}
                        title="Acessar rede social"
                        aria-label="Acessar rede social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Linkedin />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="copyright appear-fade" appear="0.2">
            <Link
              to="/politica-privacidade"
              title="Ir para a página"
              aria-label="Ir para a página"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                paddingBottom: '1rem',
              }}
            >
              Termos e condições de uso Política de privacidade
            </Link>
          </div>
          <div
            className="copyright appear-fade"
            appear="0.2"
            style={{ marginTop: '1rem' }}
          >
            ©2023 . Noz App . Todos os direitos reservados
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer
