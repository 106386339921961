import React from 'react'
import './SectionStyle.scss'

const Section = ({ data }) => {
  const items = new Array(2).fill(0)

  return (
    <section className="section section-partners">
      <div className="container">
        <div className="wrapper">
          {items.map((line, index) => (
            <div className="items" key={`home-partners-${index}`}>
              {data.partners.map((item, index2) => (
                <div
                  className="item"
                  key={`home-partners-${index}-item1-${index2}`}
                >
                  <div
                    className="item-logo no-animate"
                    rel="lazy_load"
                    data-url={item.url}
                    data-webp={item.webp}
                    data-avif={item.avif}
                    data-alt={item.alt}
                    data-distance="0.01"
                  ></div>
                </div>
              ))}

              {data.partners.map((item, index2) => (
                <div
                  className="item"
                  key={`home-partners-${index}-item2-${index2}`}
                >
                  <div
                    className="item-logo no-animate"
                    rel="lazy_load"
                    data-url={item.url}
                    data-webp={item.webp}
                    data-avif={item.avif}
                    data-alt={item.alt}
                    data-distance="0.01"
                  ></div>
                </div>
              ))}

              {data.partners.map((item, index2) => (
                <div
                  className="item"
                  key={`home-partners-${index}-item3-${index2}`}
                >
                  <div
                    className="item-logo no-animate"
                    rel="lazy_load"
                    data-url={item.url}
                    data-webp={item.webp}
                    data-avif={item.avif}
                    data-alt={item.alt}
                    data-distance="0.01"
                  ></div>
                </div>
              ))}

              {data.partners.map((item, index2) => (
                <div
                  className="item"
                  key={`home-partners-${index}-item4-${index2}`}
                >
                  <div
                    className="item-logo no-animate"
                    rel="lazy_load"
                    data-url={item.url}
                    data-webp={item.webp}
                    data-avif={item.avif}
                    data-alt={item.alt}
                    data-distance="0.01"
                  ></div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Section
