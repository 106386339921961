import { useEffect } from 'react'
import { casesSection } from 'utils/scroll-page-cases'
import { isMobile } from 'react-device-detect'

const ScrollPage = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    let root = document.querySelector('.root-page')
    let page = document.querySelector('.page-transform')
    let current = 0
    let target = 0
    let ease = isMobile ? 0.25 : 0.1

    function setupHeight() {
      const body = document.querySelector('body')
      const page = document.querySelector('.page-transform')
      const height = page.offsetHeight

      body.style.height = height + 'px'
    }

    function setupLerp(start, end, t) {
      return start * (1 - t) + end * t
    }

    function setupAnimation() {
      if (!page) return

      if (!body.classList.contains('not-scrollable')) {
        current = parseFloat(setupLerp(current, target, ease)).toFixed(2)
        target = window.scrollY

        page.style.transform = `translateY(-${current}px)`

        if (root.id === 'root-cases') {
          casesSection()
        }
      }

      requestAnimationFrame(setupAnimation)
    }

    window.addEventListener('resize', () => setupHeight())
    body.addEventListener('resize', () => setupHeight())

    body.addEventListener('finishedPreloader', function () {
      body.dispatchEvent(new Event('pageChanged'))

      setTimeout(() => {
        body.classList.remove('not-scrollable')
        setupHeight()
        setTimeout(() => setupHeight(), 1000)
        setTimeout(() => setupHeight(), 2000)
        setTimeout(() => setupHeight(), 5000)
        setupAnimation()
      }, 500)
    })

    body.addEventListener('pageChanged', () => {
      page = document.querySelector('.page-transform')
      root = document.querySelector('.root-page')

      setupHeight()
    })
  }, [])

  return false
}

export default ScrollPage
