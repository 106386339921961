import React from 'react'
import * as DOMPurify from 'dompurify'
import { isMobile } from 'react-device-detect'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

import heroImage from 'assets/images/pages/home/hero-image.png'
import elementImage1 from 'assets/images/pages/home/hero-element-1.png'
import elementImage2X1 from 'assets/images/pages/home/hero-element-2x-1.png'
import elementImage2 from 'assets/images/pages/home/hero-element-2.png'
import elementImage2X2 from 'assets/images/pages/home/hero-element-2x-2.png'
import elementImage3 from 'assets/images/pages/home/hero-element-3.png'
import elementImage2X3 from 'assets/images/pages/home/hero-element-2x-3.png'
import elementImage4 from 'assets/images/pages/home/hero-element-4.png'
import elementImage2X4 from 'assets/images/pages/home/hero-element-2x-4.png'
import elementImage5 from 'assets/images/pages/home/hero-element-5.png'
import elementImage2X5 from 'assets/images/pages/home/hero-element-2x-5.png'
import vectorImage from 'assets/images/pages/home/hero-vector.png'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  const openModalCta = () => {
    const body = document.querySelector('body')
    body.dispatchEvent(
      new CustomEvent('openModalCta', { detail: 'banner-home' }),
    )
  }

  return (
    <section className="section section-hero">
      <div className="circles">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div
              className="left-title"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.hero.title),
              }}
            ></div>

            <div className="left-description">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.hero.description),
                }}
              ></p>
            </div>

            <div className="left-cta">
              <button
                type="button"
                title="Solicite uma demonstração"
                aria-label="Solicite uma demonstração"
                onClick={() => openModalCta()}
              >
                <span>{data.hero.buttonText}</span>
                <ArrowUp />
              </button>
            </div>
          </div>

          {!isMobile && (
            <ParallaxProvider>
              <div className="right">
                <Parallax
                  className="right-element element-1"
                  translateX={['-30px', '20px']}
                  translateY={['-30px', '20px']}
                >
                  <img
                    src={elementImage1}
                    srcSet={`${elementImage2X1} 2x`}
                    alt=""
                  />
                </Parallax>

                <div className="right-element element-2">
                  <img
                    src={elementImage2}
                    srcSet={`${elementImage2X2} 2x`}
                    alt=""
                  />

                  <div className="vectors">
                    <div className="vector">
                      <img src={vectorImage} alt="" />
                    </div>

                    <div className="vector">
                      <img src={vectorImage} alt="" />
                    </div>

                    <div className="vector">
                      <img src={vectorImage} alt="" />
                    </div>

                    <div className="vector">
                      <img src={vectorImage} alt="" />
                    </div>

                    <div className="vector">
                      <img src={vectorImage} alt="" />
                    </div>

                    <div className="vector">
                      <img src={vectorImage} alt="" />
                    </div>
                  </div>
                </div>

                <Parallax
                  className="right-element element-3"
                  translateY={['0px', '-120px']}
                >
                  <img
                    src={elementImage3}
                    srcSet={`${elementImage2X3} 2x`}
                    alt=""
                  />
                </Parallax>

                <Parallax
                  className="right-element element-4"
                  translateX={['0px', '-50px']}
                  translateY={['0px', '30px']}
                >
                  <img
                    src={elementImage4}
                    srcSet={`${elementImage2X4} 2x`}
                    alt=""
                  />
                </Parallax>

                <Parallax
                  className="right-element element-5"
                  translateX={['0px', '20px']}
                  translateY={['0px', '-50px']}
                >
                  <img
                    src={elementImage5}
                    srcSet={`${elementImage2X5} 2x`}
                    alt=""
                  />
                </Parallax>

                <div className="right-image">
                  <img src={heroImage} alt="" />
                </div>
              </div>
            </ParallaxProvider>
          )}
        </div>
      </div>
    </section>
  )
}

export default Section
