import React, { useState, useEffect } from 'react'
import API from 'api'
import './BlogStyle.scss'

import Footer from 'components/general/Footer'

import SectionHero from 'components/pages/blog/SectionHero'
import SectionArticles from 'components/pages/blog/SectionArticles'
import SectionNewsletter from 'components/pages/blog/SectionNewsletter'

const Page = () => {
  const [data, setData] = useState()

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_blog',
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      setData(info)

      body.dispatchEvent(new Event('loadedContent'))
    })
  }, [])

  return (
    <div className="page-transform">
      {data ? (
        <main id="page-blog" className="page">
          <SectionHero data={data} />
          <SectionArticles data={data} />
          <SectionNewsletter data={data} />
        </main>
      ) : (
        <main id="page-blog" className="page"></main>
      )}

      <Footer />
    </div>
  )
}

export default Page
