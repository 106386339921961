import React from 'react'
import { isMobile } from 'react-device-detect'
import * as DOMPurify from 'dompurify'
import './SectionStyle.scss'

import involveImage1 from 'assets/images/pages/home/involve-image-1.png'
import involveImage2 from 'assets/images/pages/home/involve-image-2.png'

const Section = ({ data }) => {
  return (
    <section className="section section-involve">
      <div className="container">
        <div className="wrapper">
          <div
            className="wrapper-description appear-fade"
            appear="0.2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.involve.title1),
            }}
          ></div>

          <div
            className="wrapper-title appear-fade-horizontal"
            appear="0.2"
            appear-delay="600"
          >
            {data.involve.title2}
          </div>
        </div>

        <div className="content">
          <div className="content-image--1 appear-fade-vertical" appear="0.4">
            <div className="content-image--1_element">
              <img src={involveImage1} alt="" />
            </div>

            {isMobile ? (
              <div
                className="content-image--1_gif no-animate"
                rel="lazy_load"
                data-url={data.involve.gif}
                data-webp="false"
                data-avif="false"
                data-distance="0.01"
              ></div>
            ) : (
              <div className="content-image--1_video">
                <video
                  className="video-play"
                  appear="0.01"
                  muted
                  loop
                  preload="none"
                >
                  <source
                    src={data.involve.video.desktop.webm}
                    type="video/webm"
                  />
                  <source
                    src={data.involve.video.desktop.mp4}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>

          <div
            className="content-image--2 appear-fade-vertical"
            appear="0.4"
            appear-delay="100"
          >
            <div className="content-image--2_element">
              <img src={involveImage2} alt="" />
            </div>

            <div className="content-image--2_video">
              <video
                className="video-play"
                appear="0.01"
                muted
                loop
                preload="none"
              >
                <source
                  src={data.involve.video.mobile.webm}
                  type="video/webm"
                />
                <source src={data.involve.video.mobile.mp4} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
