import React, { useEffect, createRef } from 'react'
import { Helmet } from 'react-helmet'
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useOutlet,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Appear from 'utils/appear'
import LazyLoad from 'utils/lazy-load'
import ScrollPage from 'utils/scroll-page'

import Header from 'components/general/Header'
import Preloader from 'components/general/Preloader'
import ModalCta from 'components/general/ModalCta'

import Home from 'container/home'
import Products from 'container/products'
import Product from 'container/product'
import Blog from 'container/blog'
import BlogSingle from 'container/blog-single'
import Cases from 'container/cases'
import Case from 'container/case'
import PrivacyPolicy from 'container/privacy-policy'
import ContactSuccess from 'components/contact-success'
import Contact from 'container/contact'

const App = () => {
  useEffect(() => {
    const body = document.querySelector('body')

    body.addEventListener('loadedContent', function () {
      if (!window.preloader_active) {
        const pages = document.querySelectorAll('.root-page')
        let page = pages[0]

        if (pages.length >= 2) {
          for (const item of pages) {
            const classes = item.getAttribute('class')

            if (classes.includes('enter')) page = item
          }
        }

        page.classList.add('loaded-content')

        if (page.classList.contains('transition-enter-done')) {
          body.dispatchEvent(new Event('resize'))
          setTimeout(() => body.dispatchEvent(new Event('pageChanged')), 500)
        }
      }
    })
  }, [])

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, 400)
  }

  const updateScroll = (e) => {
    const body = document.querySelector('body')
    const page = document.querySelector('.root-page.transition-enter-done')

    if (page.classList.contains('loaded-content')) {
      body.dispatchEvent(new Event('resize'))
      setTimeout(() => body.dispatchEvent(new Event('pageChanged')), 500)
    }
  }

  const routes = [
    {
      path: '/',
      name: 'home',
      title: 'Início',
      element: <Home />,
      nodeRef: createRef(),
    },
    {
      path: '/produtos/:post',
      name: 'product',
      title: 'Produto',
      element: <Product />,
      nodeRef: createRef(),
    },
    {
      path: '/produtos',
      name: 'products',
      title: 'Soluções',
      element: <Products />,
      nodeRef: createRef(),
    },
    {
      path: '/blog',
      name: 'blog',
      title: 'Blog',
      element: <Blog />,
      nodeRef: createRef(),
    },
    {
      path: '/blog/:post',
      name: 'blog-single',
      title: 'Post',
      element: <BlogSingle />,
      nodeRef: createRef(),
    },
    {
      path: '/cases/:post',
      name: 'case',
      title: 'Case',
      element: <Case />,
      nodeRef: createRef(),
    },
    {
      path: '/cases',
      name: 'cases',
      title: 'Cases',
      element: <Cases />,
      nodeRef: createRef(),
    },
    {
      path: '/politica-privacidade',
      name: 'politica',
      title: 'Politica',
      element: <PrivacyPolicy />,
      nodeRef: createRef(),
    },
    {
      path: '/fale-conosco',
      name: 'fale-conosco',
      title: 'Fale Conosco',
      element: <Contact />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-home',
      name: 'contact-success',
      title: 'Sucesso - Página Inicial',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-menu',
      name: 'contact-success',
      title: 'Sucesso - Menu',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-rodape',
      name: 'contact-success',
      title: 'Sucesso - Rodapé',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-banner-home',
      name: 'contact-success',
      title: 'Sucesso - Banner - Página Inicial',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-produto',
      name: 'contact-success',
      title: 'Sucesso - Produto',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-produtos',
      name: 'contact-success',
      title: 'Sucesso - Produtos',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
    {
      path: '/sucesso-contato-fale-conosco',
      name: 'contact-success',
      title: 'Sucesso - Fale Conosco',
      element: <ContactSuccess />,
      nodeRef: createRef(),
    },
  ]

  const RenderRoutes = () => {
    const location = useLocation()
    const currentOutlet = useOutlet()
    const { name, nodeRef, title } =
      routes.find((route) => route.path === location.pathname) ?? {}

    return (
      <>
        <Header />
        <Preloader />
        <ModalCta />

        <TransitionGroup className="page-transition">
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={1000}
            classNames="transition"
            onEnter={() => scrollToTop()}
            onEntered={(e) => updateScroll(e)}
          >
            {(state) => (
              <div
                ref={nodeRef}
                id={name ? `root-${name}` : 'root'}
                className="root-page"
              >
                <Helmet>
                  {title ? (
                    <title>{title} | Noz - Plataforma de comunicação</title>
                  ) : (
                    <title>Noz - Plataforma de comunicação</title>
                  )}
                </Helmet>

                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        </TransitionGroup>
      </>
    )
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RenderRoutes />,
      children: routes.map((route) => ({
        index: route.path === '/',
        path: route.path === '/' ? undefined : route.path,
        element: route.element,
      })),
    },
  ])

  return (
    <>
      <Appear />
      <LazyLoad />
      <ScrollPage />

      <RouterProvider router={router} />
    </>
  )
}

export default App
