import React, { useEffect, useState } from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import './SectionStyle.scss'

import titleImage from 'assets/vectors/pages/home/cases-title.svg'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  const [navigation, setNavigation] = useState(0)
  const total = data.hero.length

  const scrollToCard = (index) => {
    const height = window.innerHeight
    const offset = height * index

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      const height = window.innerHeight
      const number = Number((offset / height).toFixed(0))

      setNavigation(number)
    })
  }, [])

  return (
    <section
      className="section section-cases"
      style={{
        height: `calc(var(--initial-vh, 1vh) * ${total * 100})`,
      }}
    >
      <div className="container">
        <div className="block appear-fade" appear="0">
          <div className="headline">
            <div className="headline-title">
              <img src={titleImage} alt="Imagem - Noz" />
            </div>
          </div>

          <div className="slide">
            <div className="slide-items">
              {data.hero.map((item, index) => (
                <div
                  className="item appear-fade"
                  appear="0.1"
                  key={`cases-item-${index}`}
                >
                  <div
                    className="item-background no-animate"
                    rel="lazy_load"
                    data-url={item.image.url}
                    data-webp={item.image.webp}
                    data-avif={item.image.avif}
                    data-alt={item.image.alt}
                    data-distance="0.01"
                  ></div>

                  <div
                    className="item-image no-animate"
                    rel="lazy_load"
                    data-url={item.phone.url}
                    data-webp={item.phone.webp}
                    data-avif={item.phone.avif}
                    data-alt={item.phone.alt}
                    data-distance="0.01"
                  ></div>

                  <div className="item-content">
                    <div
                      className="item-content--brand appear-fade no-animate"
                      appear="0.1"
                      appear-delay="50"
                      rel="lazy_load"
                      data-url={item.logo.url}
                      data-webp={item.logo.webp}
                      data-avif={item.logo.avif}
                      data-alt={item.logo.alt}
                      data-distance="0.01"
                    ></div>

                    <div
                      className="item-content--title appear-fade"
                      appear="0.1"
                      appear-delay="100"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.title),
                      }}
                    ></div>

                    <div
                      className="item-content--description appear-fade"
                      appear="0.1"
                      appear-delay="150"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.description),
                        }}
                      ></p>
                    </div>

                    {item.author && (
                      <div
                        className="item-content--flex appear-fade"
                        appear="0.1"
                        appear-delay="200"
                      >
                        <div className="left">
                          <div
                            className="left-image no-animate"
                            rel="lazy_load"
                            data-url={item.author.image.url}
                            data-webp={item.author.image.webp}
                            data-avif={item.author.image.avif}
                            data-alt={item.author.image.alt}
                            data-distance="0.01"
                          ></div>
                        </div>

                        <div className="right">
                          <div className="right-title">{item.author.title}</div>
                          <div className="right-subtitle">
                            {item.author.subtitle}
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="item-content--cta appear-fade"
                      appear="0.1"
                      appear-delay="250"
                    >
                      <Link
                        to={`/cases/${item.slug}`}
                        title="Veja o case"
                        aria-label="Veja o case"
                      >
                        <span>veja o case</span>
                        <ArrowUp />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="slide-navigation">
              <ul>
                {data.hero.map((item, index) => (
                  <li
                    className={`${navigation === index ? 'active' : ''}`}
                    key={`cases-navigation-${index}`}
                  >
                    <button
                      type="button"
                      aria-label="Ir para a seção"
                      onClick={() => scrollToCard(index)}
                    >
                      {item.navigation}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
