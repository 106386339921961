import React from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import './SectionStyle.scss'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  return (
    <section className="section section-products">
      <div className="container">
        <div className="cards">
          {data.products.map((item, index) => (
            <div
              className="item appear-fade-vertical"
              appear="0.3"
              key={`products-item-${index}`}
            >
              <div className="card">
                <Link
                  to={`/produtos/${item.slug}`}
                  className="card-link"
                  aria-label="Ir para a página"
                >
                  Ir para a página
                </Link>

                <div className="card-block">
                  <div
                    className="card-image no-animate"
                    rel="lazy_load"
                    data-url={item.image.url}
                    data-webp={item.image.webp}
                    data-avif={item.image.avif}
                    data-alt={item.image.alt}
                    data-distance="0.01"
                  ></div>
                </div>

                <div className="card-content">
                  <div className="card-content--title">{item.title}</div>

                  <div className="card-content--description">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.description),
                      }}
                    ></p>
                  </div>

                  <div className="card-content--items">
                    <ul>
                      {item.list.map((list, index2) => (
                        <li key={`products-item-${index}-list-${index2}`}>
                          • {list.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card-cta">
                  <Link
                    to={`/produtos/${item.slug}`}
                    aria-label="Ir para a página"
                  >
                    <span>Conheça</span>
                    <ArrowUp />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Section
