import React from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

import Image from 'components/elements/Image'

import { ReactComponent as Arrow } from 'assets/vectors/pages/case/hero-return.svg'

const Section = ({ data }) => {
  return (
    <section className="section section-hero">
      <div className="background">
        {isMobile ? (
          <Image
            url={data.hero.imageMobile.url}
            webp={data.hero.imageMobile.webp}
            avif={data.hero.imageMobile.avif}
            alt={data.hero.imageMobile.alt}
          />
        ) : (
          <Image
            url={data.hero.imageDesktop.url}
            webp={data.hero.imageDesktop.webp}
            avif={data.hero.imageDesktop.avif}
            alt={data.hero.imageDesktop.alt}
          />
        )}
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-return">
              <Link
                to="/cases"
                title="Ir para a página"
                aria-label="Ir para a página"
              >
                <Arrow />
                <span>Cases</span>
              </Link>
            </div>

            <div className="left-logo">
              <Image
                url={data.hero.logo.url}
                webp={data.hero.logo.webp}
                avif={data.hero.logo.avif}
                alt={data.hero.logo.alt}
              />
            </div>

            <div
              className="left-title"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.hero.title),
              }}
            ></div>

            <div className="left-description">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.hero.description),
                }}
              ></p>
            </div>
          </div>

          <div className="right">
            <div className="right-image">
              <Image
                url={data.hero.phone.url}
                webp={data.hero.phone.webp}
                avif={data.hero.phone.avif}
                alt={data.hero.phone.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
