import React from 'react'
import * as DOMPurify from 'dompurify'
import './SectionStyle.scss'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  return (
    <section className="section section-motives">
      <div
        className="background no-animate"
        rel="lazy_load"
        data-url={data.motives.image.url}
        data-webp={data.motives.image.webp}
        data-avif={data.motives.image.avif}
        data-alt={data.motives.image.alt}
        data-distance="0.01"
      ></div>

      <div className="container">
        <div className="headline">
          <div
            className="headline-title appear-fade"
            appear="0.2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.motives.title),
            }}
          ></div>

          <div className="headline-descriptions appear-fade" appear="0.2">
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.motives.description1),
              }}
            ></div>

            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.motives.description2),
              }}
            ></div>
          </div>
        </div>

        <div className="flex-wrapper">
          {data.motives.content.map((item, index) => (
            <div
              className="card appear-fade-vertical"
              appear="0.2"
              appear-delay={(index % 3) * 100}
              key={`home-motives-${index}`}
            >
              <div
                className="card-icon no-animate"
                rel="lazy_load"
                data-url={item.icon.url}
                data-webp={item.icon.webp}
                data-avif={item.icon.avif}
                data-alt={item.icon.alt}
                data-distance="0.01"
              ></div>

              <div className="card-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.description),
                  }}
                ></p>
              </div>
            </div>
          ))}
        </div>

        <div className="cta appear-fade" appear="0.2">
          <a href="#link" title="Ver motivos" aria-label="Ver motivos">
            <span>{data.motives.buttonText}</span>
            <ArrowUp />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Section
