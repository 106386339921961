import React from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import './SectionStyle.scss'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'
import { ReactComponent as ButtonSearch } from 'assets/vectors/pages/blog/articles-button-search.svg'
import { ReactComponent as ButtonLeft } from 'assets/vectors/general/button-left.svg'
import { ReactComponent as ButtonRight } from 'assets/vectors/general/button-right.svg'

const Section = ({ data }) => {
  return (
    <section className="section section-articles">
      <div className="container">
        <div className="headline">
          <div className="headline-left">
            <form className="headline-left--form form">
              <div className="field-group">
                <input
                  type="search"
                  className="field"
                  name="buscar"
                  placeholder="Busque uma postagem por título, conteúdo e palavra-chave"
                />
              </div>

              <div className="form-submit">
                <button type="submit" title="Buscar" aria-label="Buscar">
                  <ButtonSearch />
                </button>
              </div>
            </form>
          </div>

          <div className="headline-right">
            <div className="headline-right--title">Categoria de conteúdos:</div>

            <div className="headline-right--navigation">
              <ul>
                <li>
                  <a
                    href="#link"
                    className="active"
                    title="Artigos"
                    aria-label="Artigos"
                  >
                    Artigos
                  </a>
                </li>

                <li>
                  <a href="#link" title="Materiais" aria-label="Materiais">
                    Materiais
                  </a>
                </li>

                <li>
                  <a href="#link" title="Podcast" aria-label="Podcast">
                    Podcast
                  </a>
                </li>

                <li>
                  <a href="#link" title="Webinar" aria-label="Webinar">
                    Webinar
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex-wrapper">
          {data.posts.map((item, index) => (
            <div className="card" key={`posts-card-${index}`}>
              <Link
                to={`/blog/${item.slug}`}
                className="card-link"
                aria-label="Ir para a página"
              >
                Ir para a página
              </Link>

              <div className="card-block">
                <div
                  className="card-block--image no-animate"
                  rel="lazy_load"
                  data-url={item.imageCard.url}
                  data-webp={item.imageCard.webp}
                  data-avif={item.imageCard.avif}
                  data-alt={item.imageCard.alt}
                  data-distance="0.01"
                ></div>
              </div>

              <div className="card-content">
                <div className="card-content--flex">
                  {item.category && (
                    <div className="left">
                      <div className="left-title">{item.category.title}</div>
                    </div>
                  )}

                  <div className="right">
                    <div className="right-date">{item.date}</div>
                  </div>
                </div>

                <div className="card-content--title">{item.title}</div>
              </div>

              <div className="card-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.description),
                  }}
                ></p>
              </div>

              <div className="card-cta">
                <Link
                  to={`/blog/${item.slug}`}
                  title={data.general.buttonText}
                  aria-label={data.general.buttonText}
                >
                  <span>{data.general.buttonText}</span>
                  <ArrowUp />
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination">
          <div className="pagination-left">
            <button type="button" title="Voltar" aria-label="Voltar">
              <ButtonLeft />
            </button>
          </div>

          <div className="pagination-center">
            <ul>
              <li>
                <a
                  href="#link"
                  className="active"
                  title="Página 1"
                  aria-label="Página 1"
                >
                  1
                </a>
              </li>

              <li>
                <a href="#link" title="Página 2" aria-label="Página 2">
                  2
                </a>
              </li>

              <li>
                <a href="#link" title="Página 3" aria-label="Página 3">
                  3
                </a>
              </li>

              <li>
                <a href="#link" title="Página 4" aria-label="Página 4">
                  4
                </a>
              </li>

              <li>
                <a href="#link" title="Página 5" aria-label="Página 5">
                  5
                </a>
              </li>

              <li>
                <a href="#link" title="Página 6" aria-label="Página 6">
                  6
                </a>
              </li>
            </ul>
          </div>

          <div className="pagination-right">
            <button type="button" title="Avançar" aria-label="Avançar">
              <ButtonRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
