import React, { useEffect, useState } from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

import backgroundImage from 'assets/images/pages/home/functionalities-background.png'

import { ReactComponent as ButtonLeft } from 'assets/vectors/pages/home/functionalities-button-left.svg'
import { ReactComponent as ButtonRight } from 'assets/vectors/pages/home/functionalities-button-right.svg'
import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  const [slide, setSlide] = useState(0)
  const total = data.functionalities.products.length

  useEffect(() => {
    if (isMobile) return

    clearInterval(window.home_functionalities_slide)
    window.home_functionalities_slide = setInterval(function () {
      const next_index = slide + 1

      if (next_index === total - 1) {
        setSlide(0)
      } else {
        setSlide(next_index)
      }
    }, 5000)
  }, [slide, total])

  return (
    <section className="section section-functionalities">
      {!isMobile && (
        <div className="background">
          <img src={backgroundImage} alt="" />
        </div>
      )}

      <div className="container">
        <div className="headline">
          <div className="headline-small appear-fade" appear="0.2">
            {data.functionalities.small}
          </div>

          <div className="headline-flex appear-fade" appear="0.2">
            <div
              className="headline-flex--title"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.functionalities.title),
              }}
            ></div>

            <div className="headline-flex--arrows">
              <button
                title="Voltar slide"
                aria-label="Voltar slide"
                onClick={() => setSlide(slide - 1 < 0 ? total - 2 : slide - 1)}
              >
                <ButtonLeft />
              </button>

              <button
                title="Avançar slide"
                aria-label="Avançar slide"
                onClick={() =>
                  setSlide(slide + 1 === total - 1 ? 0 : slide + 1)
                }
              >
                <ButtonRight />
              </button>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-items">
            {data.functionalities.products.map((item, index) => (
              <div
                className="item"
                key={`product-cards-${index}`}
                style={{ transform: `translateX(-${slide * 540}px)` }}
              >
                <div
                  className="card appear-fade"
                  appear={isMobile ? '0.01' : '0.2'}
                >
                  <Link
                    to={`/produtos/${item.slug}`}
                    className="card-link"
                    aria-label="Ir para a página"
                  >
                    Ir para a página
                  </Link>

                  <div className="card-block">
                    <div
                      className="card-image no-animate"
                      rel="lazy_load"
                      data-url={item.image.url}
                      data-webp={item.image.webp}
                      data-avif={item.image.avif}
                      data-alt={item.image.alt}
                      data-distance="0.01"
                    ></div>
                  </div>

                  <div className="card-content">
                    <div className="card-content--title">{item.title}</div>

                    <div className="card-content--description">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.description),
                        }}
                      ></p>
                    </div>
                    <div className="card-content--items">
                      <ul>
                        {item.list.map((list, index2) => (
                          <li key={`product-cards-${index}-list-${index2}`}>
                            • {list.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="card-cta">
                    <Link
                      to={`/produtos/${item.slug}`}
                      aria-label="Ir para a página"
                    >
                      <span>Conheça</span>
                      <ArrowUp />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
