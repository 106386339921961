import React, { useState, useEffect } from 'react'
import API from 'api'

import Footer from 'components/general/Footer'

import SectionCTA from 'components/sections/SectionCTA'

const Page = () => {
  const [data, setData] = useState()

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_home',
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      setData(info)

      body.dispatchEvent(new Event('loadedContent'))
    })
  }, [])

  return (
    <div className="page-transform">
      {data ? (
        <main id="page-contact" className="page">
          <SectionCTA data={data} success="fale-conosco" />
        </main>
      ) : (
        <main id="page-contact" className="page"></main>
      )}

      <Footer />
    </div>
  )
}

export default Page
