import React, { useEffect } from 'react'
import axios from 'axios'
import * as DOMPurify from 'dompurify'
import progressScreen from 'utils/progress-screen'
import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  useEffect(() => {
    const section = document.querySelector('#page-blog .section-newsletter')
    const form = section.querySelector('form')
    const fields = form.querySelectorAll('.field')

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const parent = field.parentElement

      field.addEventListener('focus', () => parent.classList.remove('invalid'))
    }
  }, [])

  const formSubmit = (e) => {
    e.preventDefault()

    const form = e.target

    if (form.classList.contains('sending')) return
    form.classList.add('sending')

    const groups = form.querySelectorAll('.field-group')
    const email = groups[0].querySelector('.field').value

    progressScreen('create', 'Cadastrando e-mail...')

    if (email.length === 0) groups[0].classList.add('invalid')

    const invalids = form.querySelectorAll('.field-group.invalid')

    if (invalids.length > 0) {
      const text = 'E-mail inválido.'

      setTimeout(() => progressScreen('close', text), 1500)
      form.classList.remove('sending')

      return
    } else {
      const formData = new FormData()
      formData.append('email', email)

      let url = process.env.REACT_APP_WP_FORM_CONTACT
      url = url + '/248/feedback'

      const request = axios.post(url, formData)

      request.then((result) => {
        const text = 'E-mail cadastrado com sucesso!'

        form.classList.remove('sending')
        form.reset()

        setTimeout(() => progressScreen('close', text), 1500)
      })
    }
  }

  return (
    <section className="section section-newsletter">
      {!isMobile && (
        <div
          className="background no-animate"
          rel="lazy_load"
          data-url={data.newsletter.image.url}
          data-webp={data.newsletter.image.webp}
          data-avif={data.newsletter.image.avif}
          data-alt={data.newsletter.image.alt}
          data-distance="0.01"
        ></div>
      )}

      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div
              className="left-title"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.newsletter.title),
              }}
            ></div>

            <div className="left-description">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.newsletter.description),
                }}
              ></p>
            </div>
          </div>

          <div className="right">
            <form className="right-form form" onSubmit={(e) => formSubmit(e)}>
              <div className="field-group">
                <div className="label">E-MAIL</div>

                <input
                  type="email"
                  className="field"
                  name="email"
                  placeholder="Qual é o seu e-mail?"
                />
              </div>

              <div className="form-submit">
                <button type="submit" title="Cadastrar" aria-label="Cadastrar">
                  <span>cadastrar</span>
                  <ArrowUp />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
