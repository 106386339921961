import React, { useEffect, useState } from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

import titleImage from 'assets/vectors/pages/home/cases-title.svg'

import { ReactComponent as ButtonLeft } from 'assets/vectors/pages/home/functionalities-button-left.svg'
import { ReactComponent as ButtonRight } from 'assets/vectors/pages/home/functionalities-button-right.svg'
import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'

const Section = ({ data }) => {
  const [slide, setSlide] = useState(0)
  const total = data.cases.length

  useEffect(() => {
    if (isMobile) return

    clearInterval(window.home_cases_slide)
    window.home_cases_slide = setInterval(function () {
      const next_index = slide + 1

      if (next_index === total) {
        setSlide(0)
      } else {
        setSlide(next_index)
      }
    }, 5000)
  }, [slide, total])

  return (
    <section className="section section-cases">
      <div className="container">
        <div className="block">
          <ParallaxProvider>
            {isMobile ? (
              <Parallax
                className="headline"
                translateY={['-50px', '0px']}
                rootMargin={{
                  top: -100,
                  bottom: -450,
                }}
              >
                <div className="headline-title appear-fade" appear="0.3">
                  <img src={titleImage} alt="" />
                </div>
              </Parallax>
            ) : (
              <Parallax
                className="headline"
                translateY={['-150px', '0px']}
                rootMargin={{
                  top: -100,
                  bottom: -450,
                }}
              >
                <div className="headline-title appear-fade" appear="0.3">
                  <img src={titleImage} alt="" />
                </div>
              </Parallax>
            )}
          </ParallaxProvider>

          <div className="slide">
            <div className="slide-items">
              {data.cases.map((item, index) => (
                <div
                  className="item"
                  style={{ transform: `translateX(-${slide * 1080}px)` }}
                  key={`home-cases-${index}`}
                >
                  <div
                    className="item-background no-animate"
                    rel="lazy_load"
                    data-url={item.image.url}
                    data-webp={item.image.webp}
                    data-avif={item.image.avif}
                    data-alt={item.image.alt}
                    data-distance="0.01"
                  ></div>

                  <div
                    className="item-image no-animate"
                    rel="lazy_load"
                    data-url={item.phone.url}
                    data-webp={item.phone.webp}
                    data-avif={item.phone.avif}
                    data-alt={item.phone.alt}
                    data-distance="0.01"
                  ></div>

                  <div className="item-content">
                    <div
                      className="item-content--brand appear-fade no-animate"
                      appear="0.1"
                      appear-delay="50"
                      rel="lazy_load"
                      data-url={item.logo.url}
                      data-webp={item.logo.webp}
                      data-avif={item.logo.avif}
                      data-alt={item.logo.alt}
                      data-distance="0.01"
                    ></div>

                    <div
                      className="item-content--title appear-fade"
                      appear="0.1"
                      appear-delay="100"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.title),
                      }}
                    ></div>

                    <div
                      className="item-content--description appear-fade"
                      appear="0.1"
                      appear-delay="150"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.description),
                        }}
                      ></p>
                    </div>

                    {item.author && (
                      <div
                        className="item-content--flex appear-fade"
                        appear="0.1"
                        appear-delay="200"
                      >
                        <div className="left">
                          <div
                            className="left-image no-animate"
                            rel="lazy_load"
                            data-url={item.author.image.url}
                            data-webp={item.author.image.webp}
                            data-avif={item.author.image.avif}
                            data-alt={item.author.image.alt}
                            data-distance="0.01"
                          ></div>
                        </div>

                        <div className="right">
                          <div className="right-title">{item.author.title}</div>
                          <div className="right-subtitle">
                            {item.author.subtitle}
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="item-content--cta appear-fade"
                      appear="0.1"
                      appear-delay="250"
                    >
                      <Link
                        to={`/cases/${item.slug}`}
                        title="Veja o case"
                        aria-label="Veja o case"
                      >
                        <span>veja o case</span>
                        <ArrowUp />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="slide-arrows">
              <button
                title="Voltar"
                aria-label="Voltar"
                onClick={() => setSlide(slide - 1 < 0 ? total - 1 : slide - 1)}
              >
                <ButtonLeft />
              </button>

              <button
                title="Avançar"
                aria-label="Avançar"
                onClick={() => setSlide(slide + 1 === total ? 0 : slide + 1)}
              >
                <ButtonRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
