import React, { useEffect } from 'react'
import * as DOMPurify from 'dompurify'
import './SectionStyle.scss'

const Section = ({ data }) => {
  useEffect(() => {
    const portalId = data.hubspot.portal
    const formId = data.hubspot.form

    if (!portalId && !formId) return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          target: '#hubspot',
        })
      }
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [data.hubspot.form, data.hubspot.portal])

  return (
    <section className="section section-content">
      <div className="container">
        <div className="headline">
          <div className="headline-flex">
            {data.general.category && (
              <div className="headline-flex--category">
                {data.general.category.title}
              </div>
            )}

            <div className="headline-flex--date">{data.general.date}</div>
          </div>

          <div className="headline-title">{data.general.title}</div>
        </div>

        <div
          className="wrapper"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.content.text, {
              ADD_ATTR: ['target'],
            }),
          }}
        ></div>

        {data.hubspot.portal && <div id="hubspot"></div>}
      </div>
    </section>
  )
}

export default Section
