import React, { useState, useEffect } from 'react'
import API from 'api'
import { useParams } from 'react-router-dom'
import './CaseStyle.scss'

import Footer from 'components/general/Footer'

import SectionHero from 'components/pages/case/SectionHero'
import SectionContent from 'components/pages/case/SectionContent'
import SectionCTA from 'components/sections/SectionCTA'

const Page = () => {
  const { post } = useParams()
  const [data, setData] = useState()

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_case',
        post: post,
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      if (info) {
        setData(info)

        body.dispatchEvent(new Event('loadedContent'))
      } else {
        window.location.href = '/pagina-nao-encontrada'
      }
    })
  }, [post])

  return (
    <div className="page-transform">
      {data ? (
        <main id="page-case" className="page">
          <SectionHero data={data} />
          <SectionContent data={data} />
          <SectionCTA data={data} />
        </main>
      ) : (
        <main id="page-case" className="page"></main>
      )}

      <Footer />
    </div>
  )
}

export default Page
