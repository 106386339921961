export const casesSection = (current) => {
  const section = document.querySelector('#page-cases .section-cases')
  if (!section) return
  const secOptions = section.getBoundingClientRect()

  const sectionTop = secOptions.top
  const offset = Math.abs(sectionTop)
  const max = secOptions.height - window.innerHeight

  if (sectionTop <= 0 && Math.abs(sectionTop) > max) {
    return
  }

  const block = section.querySelector('.block')
  const slide = block.querySelector('.slide .slide-items')
  const items = slide.querySelectorAll('.item')

  if (sectionTop <= 0) {
    block.style.transform = `translateY(${offset}px)`

    const percentage = (offset / max) * 100 * (items.length - 1)

    slide.style.transform = `translateX(-${percentage}%)`
  } else {
    block.style.transform = `translateY(0px)`
    slide.style.transform = `translateX(-0%)`
  }
}
