import React, { useEffect, useState } from 'react'
import * as DOMPurify from 'dompurify'
import './SectionStyle.scss'

import Image from 'components/elements/Image'

const Section = ({ data }) => {
  const [slide, setSlide] = useState(0)
  const total = data.about.slide.length

  useEffect(() => {
    clearInterval(window.case_content_slide)
    window.case_content_slide = setInterval(function () {
      const next_index = slide + 1

      if (next_index === total - 1) {
        setSlide(0)
      } else {
        setSlide(next_index)
      }
    }, 5000)
  }, [slide, total])

  return (
    <section className="section section-content">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-slide">
              <div className="slide-items">
                {data.about.slide.map((item, index) => (
                  <div
                    className={`item ${slide === index ? 'active' : ''}`}
                    key={`case-slide-image-${index}`}
                  >
                    <div
                      className="item-image no-animate"
                      rel="lazy_load"
                      data-url={item.url}
                      data-webp={item.webp}
                      data-avif={item.avif}
                      data-alt={item.alt}
                      data-distance="0.01"
                    ></div>
                  </div>
                ))}
              </div>

              {total > 1 && (
                <div className="slide-dots">
                  {data.about.slide.map((item, index) => (
                    <div
                      className={`dot ${slide === index ? 'active' : ''}`}
                      key={`case-slide-dot-${index}`}
                      onClick={() => setSlide(0)}
                    ></div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="right">
            <div
              className="right-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.about.description),
              }}
            ></div>
          </div>
        </div>

        <div className="text">
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.about.text),
            }}
          ></p>
        </div>

        <div className="gallery">
          <div className="gallery-circles">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>

          <div className="gallery-image">
            <Image
              url={data.about.image.url}
              webp={data.about.image.webp}
              avif={data.about.image.avif}
              alt={data.about.image.alt}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
