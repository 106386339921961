import React, { useState, useEffect } from 'react'
import API from 'api'
import './CasesStyle.scss'

import SectionCases from 'components/pages/cases/SectionCases'

const Page = () => {
  const [data, setData] = useState()

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_cases',
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      setData(info)

      body.dispatchEvent(new Event('loadedContent'))
    })
  }, [])

  return (
    <div className="page-transform">
      {data ? (
        <main id="page-cases" className="page">
          <SectionCases data={data} />
        </main>
      ) : (
        <main id="page-cases" className="page"></main>
      )}
    </div>
  )
}

export default Page
