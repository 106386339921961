import React, { useState, useEffect } from 'react'
import API from 'api'
import './HomeStyle.scss'

import Footer from 'components/general/Footer'

import SectionHero from 'components/pages/home/SectionHero'
import SectionNumbers from 'components/pages/home/SectionNumbers'
import SectionInvolve from 'components/pages/home/SectionInvolve'
import SectionFunctionalities from 'components/pages/home/SectionFunctionalities'
import SectionCases from 'components/pages/home/SectionCases'
import SectionPartners from 'components/pages/home/SectionPartners'
import SectionMotives from 'components/pages/home/SectionMotives'
import SectionCTA from 'components/sections/SectionCTA'

const Page = () => {
  const [data, setData] = useState()

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_home',
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      setData(info)

      body.dispatchEvent(new Event('loadedContent'))
    })
  }, [])

  return (
    <div className="page-transform">
      {data ? (
        <main id="page-home" className="page">
          <SectionHero data={data} />
          <SectionNumbers data={data} />
          <SectionInvolve data={data} />
          <SectionFunctionalities data={data} />
          <SectionCases data={data} />
          <SectionPartners data={data} />
          <SectionMotives data={data} />
          <SectionCTA data={data} />
        </main>
      ) : (
        <main id="page-home" className="page"></main>
      )}

      <Footer />
    </div>
  )
}

export default Page
