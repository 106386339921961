import React, { useState } from 'react'
import * as DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/cta-arrow-up.svg'
import { ReactComponent as ButtonLeft } from 'assets/vectors/general/button-left.svg'
import { ReactComponent as ButtonRight } from 'assets/vectors/general/button-right.svg'

const Section = ({ data }) => {
  const [slide, setSlide] = useState(0)
  const total = data.hero.slide.length

  return (
    <section className="section section-hero">
      <div className="slide">
        <div className="slide-items">
          {data.hero.slide.map((item, index) => (
            <div
              className={`item ${slide === index ? 'active' : ''}`}
              key={`blog-slide-${index}`}
            >
              {isMobile ? (
                <div
                  className="item-background no-animate"
                  rel="lazy_load"
                  data-url={item.imageSlideMobile.url}
                  data-webp={item.imageSlideMobile.webp}
                  data-avif={item.imageSlideMobile.avif}
                  data-alt={item.imageSlideMobile.alt}
                  data-distance="0.01"
                ></div>
              ) : (
                <div
                  className="item-background no-animate"
                  rel="lazy_load"
                  data-url={item.imageSlideDesktop.url}
                  data-webp={item.imageSlideDesktop.webp}
                  data-avif={item.imageSlideDesktop.avif}
                  data-alt={item.imageSlideDesktop.alt}
                  data-distance="0.01"
                ></div>
              )}

              <div className="item-wrapper container">
                <div className="item-wrapper--flex">
                  {item.category && (
                    <div className="left">
                      <div className="left-title">{item.category.title}</div>
                    </div>
                  )}

                  <div className="right">
                    <div className="right-date">{item.date}</div>
                  </div>
                </div>

                <div className="item-wrapper--title">{item.title}</div>

                <div className="item-wrapper--description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.description),
                    }}
                  ></p>
                </div>

                <div className="item-wrapper--cta">
                  <Link
                    to={`/blog/${item.slug}`}
                    title={data.general.buttonText}
                    aria-label={data.general.buttonText}
                  >
                    <span>{data.general.buttonText}</span>
                    <ArrowUp />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {total > 1 && (
          <div className="slide-arrows container">
            <button
              title="Voltar slide"
              aria-label="Voltar slide"
              onClick={() => setSlide(slide - 1 < 0 ? total - 1 : slide - 1)}
            >
              <ButtonLeft />
            </button>

            <button
              title="Avançar slide"
              aria-label="Avançar slide"
              onClick={() => setSlide(slide + 1 === total ? 0 : slide + 1)}
            >
              <ButtonRight />
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default Section
