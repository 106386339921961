import React, { useState, useEffect } from 'react'
import API from 'api'
import './ProductsStyle.scss'

import Footer from 'components/general/Footer'

import SectionHero from 'components/pages/products/SectionHero'
import SectionProducts from 'components/pages/products/SectionProducts'
import SectionCTA from 'components/sections/SectionCTA'

const Page = () => {
  const [data, setData] = useState()

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_products',
      },
    }).then((result) => {
      const body = document.querySelector('body')
      const info = result.data

      setData(info)

      body.dispatchEvent(new Event('loadedContent'))
    })
  }, [])

  return (
    <div className="page-transform">
      {data ? (
        <main id="page-products" className="page">
          <SectionHero data={data} />
          <SectionProducts data={data} />
          <SectionCTA data={data} />
        </main>
      ) : (
        <main id="page-products" className="page"></main>
      )}

      <Footer />
    </div>
  )
}

export default Page
