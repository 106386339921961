import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import '../sections/SectionCTA/SectionStyle.scss'

import Footer from 'components/general/Footer'

const Page = () => {
  const navigate = useNavigate()

  return (
    <div className="page-transform">
      <main id="page-contact-success" className="page">
        <section className="section section-general-cta">
          {!isMobile && (
            <div className="background no-animate" data-distance="0.01">
              <picture>
                <source
                  srcSet="https://cms.appnoz.com.br/wp-content/uploads-webpc/uploads/2023/06/appnoz-cta-background.jpg.avif"
                  type="image/avif"
                />
                <img
                  src="https://cms.appnoz.com.br/wp-content/uploads/2023/06/appnoz-cta-background.jpg"
                  alt="Imagem - Noz"
                  rel="lazy"
                />
              </picture>
            </div>
          )}
          <div className="container">
            <div className="flex-wrapper">
              <div className="right mx-auto">
                <div className="right-form form">
                  <h1>Mensagem enviada com sucesso</h1>
                  <div className="mt-30">
                    <button
                      type="button"
                      title="Voltar"
                      aria-label="Voltar"
                      onClick={() => navigate(-1)}
                    >
                      <span>← voltar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default Page
