import React, { useEffect, useRef } from 'react'
import * as DOMPurify from 'dompurify'
import './SectionStyle.scss'

const Section = ({ data }) => {
  const section = useRef(null)

  useEffect(() => {
    if (!section.current) return

    const element = section.current
    const items = element.querySelectorAll('.flex-wrapper .item')

    const animateValue = (el, start, end, duration) => {
      let current = start
      const increment = end > start ? 1 : -1

      const timer = setInterval(function () {
        current += increment

        el.innerHTML = current

        if (current === end) {
          clearInterval(timer)
        }
      }, duration)
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const number = Number(item.getAttribute('data-value'))
      const value = item.querySelector('span')
      const duration = Number(item.getAttribute('data-duration'))

      item.addEventListener('appeared', function () {
        animateValue(value, 0, number, duration)
      })
    }
  }, [section])

  return (
    <section className="section section-numbers" ref={section}>
      <div className="container">
        <div className="flex-wrapper">
          <div
            className="item appear-fade-vertical"
            data-value={data.numbers.block1.number}
            appear="0.3"
            appear-delay="0"
            data-duration="10"
          >
            <div className="item-counter">
              <span>0</span>
              <small>{data.numbers.block1.text}</small>
            </div>

            <div
              className="item-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.numbers.block1.description),
              }}
            ></div>
          </div>

          <div
            className="item appear-fade-vertical"
            data-value={data.numbers.block2.number}
            appear="0.3"
            appear-delay="100"
            data-duration="20"
          >
            <div className="item-counter">
              <span>0</span>
              <small>{data.numbers.block2.text}</small>
            </div>

            <div
              className="item-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.numbers.block2.description),
              }}
            ></div>
          </div>

          <div
            className="item appear-fade-vertical"
            data-value={data.numbers.block3.number}
            appear="0.3"
            appear-delay="200"
            data-duration="20"
          >
            <div className="item-counter">
              <span>0</span>
              <small>{data.numbers.block3.text}</small>
            </div>

            <div
              className="item-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.numbers.block3.description),
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
