import React from 'react'
import './SectionStyle.scss'

import Image from 'components/elements/Image'

const Section = ({ data }) => {
  return (
    <section className="section section-hero">
      <div className="container">
        <div className="wrapper">
          <div className="wrapper-image">
            <Image
              url={data.hero.image.url}
              webp={data.hero.image.webp}
              avif={data.hero.image.avif}
              alt={data.hero.image.alt}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
